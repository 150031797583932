import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "|스테이지파이브ㆍ미디어로그ㆍ세종텔레콤ㆍ스마텔ㆍSK텔링크ㆍKT M 모바일 6개社 참여",
  "|가입자 1천만 눈앞, MVNO 서비스 개시 10년…활성화와 상생 위한 대승적 차원서 큰 뜻 모아",
  "|과기정통부 장석영 2차관, “알뜰폰 시장 발전방향과 신규 융합서비스 모델 적극적 제시 부탁”",
  "|연내 MVNO 신규 가치 발굴 및 산업 발전 위한 정책 연구 진행",
]

const content = () => (
  <div>
    <br />
    <div className="descImage promotion">
      <div className="img">
        <img src="/images/press/9/dDqLdBRr.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △ 26일(금) MVNO 6개사(社)가 ‘MVNO(알뜰폰) 서비스 활성화를 위한 신규 가치 발굴 및 산업 발전을 위한 정책 연구 협약(MOU)’을
        체결했다. 왼쪽부터 미디어로그 조용민 담당, KT M 모바일 전승배 본부장, 스테이지파이브 이세연 실장, SK텔링크 김광주 본부장,
        세종텔레콤 백민협 이사, 스마텔 오인환 이사
      </div>
    </div>
    <br />
    <p>
      <br />
      26일(금) MVNO 6개사(社)가 ‘MVNO(알뜰폰) 서비스 활성화를 위한 신규 가치 발굴 및 산업 발전을 위한 정책 연구 협약(MOU)’을
      체결했다.
      <br />
    </p>
    <br />
    <p>
      참여사는 스테이지파이브를 비롯해 미디어로그ㆍ세종텔레콤ㆍ스마텔ㆍSK텔링크ㆍKT M 모바일 등 총 6개사다. 이날 MOU에는 참여사가
      모두 한 자리에 모여 한 마음 한 뜻을 확인했다.
      <br />
    </p>
    <br />
    <p>
      이번 협약은 MVNO(알뜰폰) 서비스가 개시된 지 10주년만의 결실인만큼 과학기술정보통신부(이하 과기정통부)가 후원했다. MVNO
      가입자 수는 현재 1천만 돌파를 눈앞에 두고 있다.
      <br />
    </p>
    <br />
    <p>
      과기정통부 장석영 2차관은 이날 서면 축사를 통해 “알뜰폰 공동협력 연구를 위한 협약 체결을 진심으로 축하한다”며 “정부가 디지털
      뉴딜을 추진하고 있고, 알뜰폰 시장 역시 지속적 성장을 위해서는 IoT나 AI와 같은 신규 기술을 적용한 신규 사업모델 발굴이
      중요하다는 점에서 이번 공동연구협약은 알뜰폰 활성화, 나아가 융합 서비스 창출에 큰 의의가 있다고 본다”고 전했다.
      <br />
    </p>
    <br />
    <p>
      이어 “알뜰폰 사업자들이 전체 시장 발전을 위하여 뜻을 모아 공동으로 연구를 진행한다는 점에서 앞으로 도출될 성과가
      기대된다”면서 “공동연구를 통해 알뜰폰 시장 발전 방향과 신규 융합 서비스 모델을 적극적으로 제시해주시길 부탁드리고, 정부도
      사업자들이 제시해주시는 비전과 정책제안을 충실히 반영하여 정책을 수립하도록 노력하겠다”고도 덧붙였다.
      <br />
    </p>
    <br />
    <p>
      이번 MOU를 통해 정책 연구는 앞으로 세 가지 방향에서 진행될 예정이다. △기존 음성 중심 산업의 패러다임 혁신으로 소비자에게
      보다 다양한 가치를 제공할 수 있는 MVNO 모델 개발 △AIoT, IoE(Internet of Everything) 시대 MVNO생태계 발전을 위한 방향성 제고
      △공통 아젠다 도출 ㆍ 연구 ㆍ 해당 산업 활성화 정책 제안 등이 핵심이다.
      <br />
    </p>
    <br />
    <p>
      특히 MVNO 시장의 신규 가치를 계발 주제에 대해서는 △ AIoT 기반 신규 상품 가치 발굴 △다양한 SaaS 비즈니스에 맞는 MVNO 지원
      체계 마련 △MVNO 기반 구독형 서비스 모델 발굴 △MVNO 포용정책 아이디어 도출을 목표로 연구하고 내용을 공유할 예정이다.
      <br />
    </p>
    <br />
    <p>
      6개사는 MOU를 통해서 MVNO 활성화를 위해 다양한 디바이스 공동 소싱 대응과 일본의 MVNE와 같은 새로운 형태의 사업 인프라 모델
      제시, 기존 MVNO 요금제와 외부 다양한 서비스를 번들링 할 수 있는 연계 체계 마련 및 사회 소외계층을 위한 공공재 성격의 특화
      요금제 기틀을 마련할 것으로 기대하고 있다.
      <br />
    </p>
    <br />
    <p>
      공동 연구는 내달 중 연구 주제 선정을 시작하며 본격적인 가동에 들어간다.
      <br />
    </p>
    <br />
  </div>
)

const press9 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실<br />한국알뜰통신사업자협회"
      writer="커뮤니케이션팀 pr@stagefive.com <br />사무국장 하장직 ocean@kmvno.or.kr / 010-2449-8291 "
      date="2021년 3월 5일 조간 요청"
      dateType="embargo"
      pageInfo="* 총 2매/ 첨부: 사진 2매"
      title="MVNO(알뜰폰) 6개社 MOU, 26일 MVNO 활성화에 한 마음 한 뜻"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press9
